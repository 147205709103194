<template>
<div class="sinoepc-weifang">
      <div class="page flex-col">
        <div class="main1 flex-col" v-lazy:background-image="require('../../assets/sinoepc/weifang.jpeg')">
          <div class="main2 flex-col">
          <!--活动介绍-->
          <div class="sinoepc-cell-group">
          <van-cell-group>
              <van-cell size="large" title="活动介绍">
                      <template #label>
                        <div class="sinoepc-label-intro">
                          2022年6月10日至30日   通过“云闪付”APP成功领取10元消费券的客户，到中石化青州公司任意加油站下载“易捷加油”APP通过电子钱包加汽油享受满200元立减10元优惠活动。
                        </div>
                      </template>
              </van-cell>
          </van-cell-group>
          </div>



           <!--优惠券详情-->
           <div class="sinoepc-cell-group">
          <van-cell-group inset>
           <van-cell size="large" :title="coupon.title">
             <template #right-icon>
                 <div style="color:green">【{{coupon.presentNum}}元】</div>
             </template>
             <template #label>

               <div class="sinoepc-label van-ellipsis">有效期：{{coupon.startTime}}-{{coupon.endTime}}</div>
               <div class="sinoepc-label">剩余量：{{coupon.surplus}}/{{coupon.quantity}}</div>
             </template>
             </van-cell>
          </van-cell-group>

          <!--使用规则-->
          <van-cell-group inset>
              <van-cell title="" >
                  <template #label>
                      <div v-html="coupon.des"></div>
                       <!--授权按钮-->
                       <UPButton v-if="sendCode == -1"  @click="onGrant" :scope="scope">授权登录</UPButton>
                       <!--领取按钮-->
                       <van-button v-else-if="sendCode == 0" size="large" class="sinoepc-button" color="linear-gradient(to right, #ff976a, #ed6a0c, #ff6034)" @click="onSend" :loading="sendLoading" loading-text="提交中...">立即领取</van-button>
                       <!--去使用-->
                      <van-button v-else-if="sendCode == 200" size="large" type="primary" class="sinoepc-button">领取成功</van-button>
                      <!--领取失败-->
                      <van-button v-else-if="sendCode == 500" size="large" type="danger" class="sinoepc-button">领取失败</van-button>
                  </template>
              </van-cell>
          </van-cell-group>
          </div>
          </div>
        </div>
      </div>
</div>
</template>
<script>
import { setToken } from '@/utils/auth'
import { parseTime } from "@/utils/common";
import { getOpenId } from "@/api/login";
import { queryCoupon, applyCoupon } from "@/api/sinoepc";

import Vue from 'vue';
import { Image as VanImage, Button, Dialog, Lazyload, Cell, CellGroup } from 'vant';
Vue.use(Lazyload);
export default {
    name: 'SinoepcWeifang',
    components: {
         [VanImage.name]: VanImage,
         [Button.name]: Button,
         [Dialog.name]: Dialog,
         [Cell.name]: Cell,
         [CellGroup.name]: CellGroup
    },
    data() {
        return {
          // 优惠券详情
          couponId: 37070001,
          coupon: { },

          // 用户唯一标识
          openId: '',

          // 手机号授权域
          scope: "scope.mobile",

          // 发送结果
          sendCode: -1,
          sendMessage: '',
          sendStatus: '',
          sendLoading: false,
          sendDialogTitle: '',
          sendDialogBtnColor: '',
       }
    },
   created() {
      this.onInit();
      //this.coupon.startTime = parseTime(this.coupon.startTime, "{y}.{m}.{d}");
      //this.coupon.endTime = parseTime(this.coupon.endTime, "{y}.{m}.{d}");
   },
   methods: {
      //初始化
      onInit() {
         // 设置Header
         setToken("0006");

         queryCoupon(this.couponId).then(response => {
            //console.log(response);
            if(response.code === 200) {
                this.coupon = response.data;
                this.coupon.startTime = parseTime(this.coupon.startTime, "{y}.{m}.{d}");
                this.coupon.endTime = parseTime(this.coupon.endTime, "{y}.{m}.{d}");
            }
         });
      },
      //授权登录
      onGrant(event, err, result) {
        if(result !== undefined) {
            //let code = result.code;
            //console.log(this.openIdParams);
            //alert(result.code);
            console.log(result.code);

            getOpenId(result.code, 'upapi_mobile').then(response => {
                console.log(response);
                this.openId = response.data.openId;
                this.sendCode = 0;
            });
        }
      },
      //申请领券
      onSend() {
          //6月10日 晚上8点以后 才可以领券
          //let currentD = parseTime(new Date(), "{y}{m}{d}");
          //let currentH = parseTime(new Date(), "{h}");
          //if(currentD === "20220610") {
              //if(currentH < "20") {
                  //return Dialog.alert({ message: '请于6月10日20点以后开始抢券。', theme: 'round-button'});
              //}
          //}

          this.sendLoading = true;
          //this.couponId = 1; //测试卡券

          let applyParams = { couponId: this.couponId, openId: this.openId, count: 1 };
          applyCoupon(applyParams).then(response => {
              this.sendLoading = false;
              if(response.code === 200) {
                  this.sendDialogTitle = '优惠券领取成功，请前往微信小程序“易捷加油”查看票券，并进行核销使用。';
                  this.sendCode = 200;
                  this.sendDialogBtnColor = '#07c160';
              } else if(response.code === 701) {
                  this.sendMessage = '您已领取优惠券，请前往微信小程序“易捷加油”查看票券，并进行核销使用。';
                  this.sendCode = 500;
              } else {
                this.sendMessage = response.msg;
                this.sendCode = 500;
              }
              // 弹出结果
              return Dialog.alert({
                   title: this.sendDialogTitle,
                   message: this.sendMessage,
                   theme: 'round-button',
                   confirmButtonColor: this.sendDialogBtnColor
              });
          });
      },
   }
};
</script>
<style scoped>@import '../../assets/voucher/common.css';</style>
<style scoped>@import '../../assets/sinoepc/weifang.css';</style>

<style scoped>
.up-button {
   height:12vw;
   width:100%;
   font-size:16px;
}
.sinoepc-button {
  border-radius: 9px;
}
.sinoepc-label-intro {
    /*padding-top:5px;*/
    color: #646566;
    /*line-height: 20px*/
}
.sinoepc-label {
  /*font-weight:bold;*/
  color: #646566;
  white-space:nowrap;
  padding-bottom:5px;
}
.sinoepc-cell-group {
  padding-bottom:10px;
}
.van-cell--large .van-cell__title {
    color:#ed6a0c;
    font-weight:bold;
    /*font-size:20px;*/
    /*font-size:20px;*/
}



</style>
